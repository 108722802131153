<template>
    <div>
        <section class="g-bg-pos-center g-bg-size-cover" style="background-image: url(assets/img/fondo-asociados-wide.png);">
            <div class="row no-gutters">
                <div class="col-md-6 order-md-1 g-bg-asociados">
                    <div class="g-px-50 g-py-50">
                        <div class="g-mb-100">
                            <h1 class="g-color-white g-font-weight-600 g-font-size-40 mb-4 font-color-title2">Bancos Adscritos</h1>
                            <p class="lead g-color-white g-font-weight-400 font-color-subtitle2">Los bancos adscritos al sistema de resolución de conflictos son:</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
              <Banks/>
              </section>
    </div>
</template>
<script>
import Banks from "@/components/PublicSite/Banks";
export default 
{    
    components: {Banks},
    beforeMount () {
        window.scrollTo(0, 0);
    },
}
</script>
<style scoped>
    .font-color-subtitle2 {
    font-weight: 400;
    font-size: 17px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
   .font-color-title2 {
    font-weight: 600;
     font-size: 2.85714rem !important;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
</style>